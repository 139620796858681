<template>
  <base-card>
    <v-card-text>
      <div>
        <v-row class="align-end mb-6">
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            offset-lg="2"
          >
            <v-text-field
              v-model="city"
              hide-details
              clearable
              :label="$t('address.city')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="street"
              hide-details
              clearable
              :label="$t('address.streetName')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="streetNumber"
              hide-details
              clearable
              :label="$t('address.streetNumber')"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex justify-center justify-md-start"
          >
            <v-btn
              class="primary px-4 mr-4"
              @click="searchAccesses()"
            >
              {{ $t("common.search") }}
            </v-btn>

            <v-btn
              class="error px-4"
              @click="resetForm">
              {{ $t("common.reset") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-alert
          border="left"
          outlined
          type="error"
          elevation="2"
          class="mb-6 mt-2 text-left"
          dismissible
          text
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </v-alert>
      </div>

      <v-data-table
        :headers="headers"
        :items="tableData"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4 mainText--text"
      >
        <template v-slot:item.address="{item}">
          <span>{{ item.street }} {{ item.streetNumber }} {{ item.streetNo }} {{ item.streetSuffix }}</span>
          <br />
          <span>{{ item.zipCode }} {{ item.city }}</span>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  export default {
    metaInfo: {
      title: 'Search Openuniverse Networks',
    },

    data () {
      return {
        tableData: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
        city: '',
        street: '',
        streetNumber: '',
        errorMessage: '',
      }
    },

    watch: {
      currentPage(val) {
        this.searchAccesses(val)
      }
    },

    mounted () {
      this.headers = [{
        text: this.$t('access.id'),
        value: 'accessId',
      }, {
        text: this.$t('access.premises'),
        value: 'premisesType',
      }, {
        text: this.$t('access.company'),
        value: 'company',
      }, {
        text: this.$t('access.mduApartmentNumber'),
        value: 'mduApartmentNumber',
      }, {
        text: this.$t('access.mduDistinguisher'),
        value: 'mduDistinguisher',
      }, {
        text: this.$t('access.media'),
        value: 'media',
      }, {
        text: this.$t('access.service'),
        value: 'service',
      }, {
        text: this.$t('address.address'),
        value: 'address',
      }]

      this.searchAccesses()
    },

    methods: {
      ...mapActions({
        getAccessesByAddress: 'getAccessesByAddress',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async searchAccesses(currentPage = 1) {
        this.currentPage = currentPage
        this.errorMessage = ''

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const payload = {
            city: this.city,
            street: this.street,
            number: this.streetNumber,
            offset: (currentPage - 1) * this.limit,
            limit: this.limit,
            type: 'openuniverse',
          }

          const response = await this.getAccessesByAddress(payload)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.tableData = response.rows
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          this.errorMessage = util.getErrorResponse(error)
        }
        this.setLoading(false)
      },

      resetForm() {
        this.errorMessage = ''
        this.city = ''
        this.street = ''
        this.streetNumber = ''
        this.tableData = []
        this.currentPage = 1
        this.totalPages = 1
      },
    },
  }
</script>
